<template>
  <div>

    <!-- Banner -->
    <banner
      :back-image="bannerImage"
      height="550"
    />

    <div class="container my-5">

      <!-- Title -->
      <b-row>
        <b-col>
          <h1 class="text-center">
            {{ $t('innovationTitle') }}
          </h1>
        </b-col>
      </b-row>

      <!-- Certificate Image -->
      <b-row>
        <b-col
          v-for="(image, index) in imageCertificate"
          :key="index"
          cols="12"
          class="d-flex align-items-center justify-content-center mb-3"
        >
          <b-img-lazy
            :src="image"
            class="innovationCertificate border"
            alt="Certificate Pymes"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import Banner from 'Components/Common/Banner.vue'
import {
  BImgLazy,
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  name: 'Innovation',
  components: {
    Banner,
    BImgLazy,
    BRow,
    BCol,
  },
  data() {
    return {
      imageCertificate: [
        this.$t('innovation.image1'),
        this.$t('innovation.image2'),
        this.$t('innovation.image3'),
      ],
      bannerImage: '/static/limmat-images/commitments/innovacion.jpg',
    }
  },
}
</script>

<style scoped>
  .innovationCertificate {
    border: 1px solid !important;
    width: 75%;
  }

  @media (max-width: 499px){
    .innovationCertificate {
      width: 100%;
    }
  }
</style>
